import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import Typography from "@mui/material/Typography";
import {Autocomplete, InputAdornment, TextField, Tooltip} from "@mui/material";
import {styled} from "@mui/material/styles";
import {getTableData} from "../../../../../store/selectors/datatableSelector";
import {Warning} from "@mui/icons-material";
import {useFetchDevices} from "../../../../../store/actions/deviceAction";
import i18n from "../../../../../i18n/i18n";

const StyledTextField = styled(TextField)({
    '& .MuiInput-underline:before': {
        borderBottom: '1px dashed rgb(109, 51, 26) ',
        borderRight: '1px dashed rgb(109, 51, 26) '
    },
    '&  .Mui-disabled.MuiInput-underline:before': {
        borderBottom: '1px dashed #CECECE ',
        borderRight: '1px dashed  #CECECE '
    },
    '& .MuiInput-underline:after': {
        borderBottom: '1px solid rgb(109, 51, 26)',
        borderRight: '1px solid rgb(109, 51, 26)'
    },
});

export default function WeegbrugDropdown(props) {
    const { fetchDevices } = useFetchDevices();
    const weighBridge = useSelector(state => getTableData(state, "weigh_bridge"));
    const [weighBridgeAlert, setWeighbridgeAlert] = React.useState({
        priority: 'warning',
        visible: false,
        message: ''
    });

    // ======================
    // Get Data
    // ======================
    const fetchWeighBridge = function () {
        fetchDevices( 0, 0, null, '10', [1, 5], true, true, ['conditions'])
    }
    useEffect(() => {
        if ((weighBridge?.items) && weighBridge?.items.length > 0 || (props.action === 'view' && props.savedData?.state_id !== '10')) {
            return;
        }
        if (props.viewValueOnly) {
            return;
        }
        fetchWeighBridge();
    }, [])


    useEffect(() => {
        if (!props.values) {
            return;
        }
        if (props.values.state_id === '10' && props.values.weight_bridge_state === '20') {
            if (props.values.weight_bridge_name) {
                props.setFieldValue("weight_bridge_name", '');
            }
            if (props.values.weight_bridge_state) {
                props.setFieldValue("weight_bridge_state", '');
            }
            if (props.values.weight_bridge_uuid) {
                props.setFieldValue("weight_bridge_uuid", '');
            }
        }
    }, [])

    // MONITOR -  project change
    useEffect(() => {
        if (props.values.weighing_weighbridge_mandatory === 1 && !props?.values?.weight_bridge_uuid) {
            handleWeighbridgeAlert('warning', true, 'Op dit project zijn de instellingen zo geconfigureerd dat alleen gewichten van een weegbrug worden geaccepteerd. Momenteel is er echter nog geen weegbrug geselecteerd. Gelieve deze in te vullen.')
        } else {
            handleWeighbridgeAlert()
        }

    }, [props.values?.project_id, props?.values?.weight_bridge_uuid, props.values.weighing_weighbridge_mandatory])

    // MONITOR - weigh bridge weight_bridge_uuid is changed check if state name is still correct
    useEffect(() => {
        const {items} = weighBridge || {};
        const {weight_bridge_uuid, weight_bridge_name} = props?.values || {};

        if (items?.length && weight_bridge_uuid) {
            const currentWeighbridge = items.find(item => item.serial_number === weight_bridge_uuid);

            if (currentWeighbridge) {
                const {name, state_id} = currentWeighbridge;

                if (name !== weight_bridge_name) {
                    props.setFieldValue("weight_bridge_name", name);
                    props.setFieldValue("weight_bridge_state", state_id);
                }
            } else {
                props.setFieldValue("weight_bridge_name", '');
                props.setFieldValue("weight_bridge_state", '');
            }
        }
    }, [props?.values?.weight_bridge_uuid, weighBridge]);

    //handle alert on weighbridge field
    const handleWeighbridgeAlert = (priority = 'warning', visible = false, message = '') => {
        setWeighbridgeAlert({
            priority: priority,
            visible: visible,
            message: message
        })
    }

    // ======================
    // Selectbox
    // ======================
    const GetWeegbrugSelectBox = (items) => {
        return <React.Fragment>
            <Autocomplete
                disablePortal
                id="weight_bridge_uuid"
                name="weight_bridge_uuid"
                options={items || []}
                onOpen={props.handleBlur}
                value={props.values.weight_bridge_name || null}
                defaultValue={null}
                getOptionLabel={(option) =>
                    option.name ? option.name : option || null
                }
                noOptionsText={i18n.t('NO_OPTIONS')}
                isOptionEqualToValue={(option, value) => {
                    if (!value) return false;
                    let selected;
                    if (!value.name) {
                        selected = value;
                    } else {
                        selected = value.name
                    }
                    return option.name === selected;
                }}

                onChange={(e, value) => {
                    props.setFieldValue("weight_bridge_uuid", value !== null ? value.serial_number : "");
                    props.setFieldValue("weight_bridge_name", value !== null ? value.name : "");
                    if (value?.name) {
                        handleWeighbridgeAlert()
                    }
                }}
                disabled={props.disabled}
                renderOption={(props, option) => {
                    const {key, ...optionProps} = props;
                    return <li key={key} {...optionProps}>
                        <div>
                            <Typography display='block' gutterBottom={false}>{option.name}</Typography>
                            <Typography color="textSecondary" variant="subtitle2"
                                        gutterBottom={true}>S/N: {option.serial_number}</Typography>
                        </div>
                    </li>
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        helperText={props.touched?.weight_bridge_uuid && props.errors?.weight_bridge_uuid}
                        error={props.touched?.weight_bridge_uuid && Boolean(props.errors?.weight_bridge_uuid)}
                        name="weight_bridge_uuid"
                        label={props.hideLabel ? null : 'Weegbrug'}
                        variant={props.variant || "outlined"}
                        color={weighBridgeAlert.visible ? weighBridgeAlert.priority : 'primary'}
                        focused={weighBridgeAlert.visible ? true : false}
                        slotProps={{
                            inputLabel: {shrink: true},
                            input: {
                                ...params.InputProps,
                                startAdornment: (
                                    weighBridgeAlert.visible ?
                                        <InputAdornment position="start">
                                            <Tooltip
                                                title={`Op dit project zijn de instellingen zo geconfigureerd dat alleen gewichten van een weegbrug worden geaccepteerd. Momenteel is er echter nog geen weegbrug geselecteerd. Gelieve deze in te vullen.`}>
                                                <Warning color={weighBridgeAlert.visible ? weighBridgeAlert.priority : 'default'}
                                                         sx={{cursor: 'pointer'}}/>
                                            </Tooltip>
                                        </InputAdornment> : null
                                ),
                            }
                        }}
                    />
                )}
            />

        </React.Fragment>
    }

    const ViewOnlyWeegbrugSelectBox = () => {
        return <React.Fragment>
            <StyledTextField
                label={props.hideLabel ? null : 'Weegbrug'}
                slotProps={{
                    inputLabel: {shrink: true},
                }}
                margin="none"
                fullWidth
                disabled={props.disabled}
                value={props.values.weight_bridge_name || ''}
                variant={props.variant || "outlined"}
            />
        </React.Fragment>
    }


    let selectbox = "";

    if ((props.action !== 'view' || props.savedData?.state_id === '10') && !props.viewValueOnly) {
        selectbox = GetWeegbrugSelectBox(weighBridge?.items);
    } else {
        selectbox = ViewOnlyWeegbrugSelectBox();
    }

    // ======================
    // Return Selectbox
    // ======================
    return <React.Fragment>
        {selectbox}
    </React.Fragment>;
}

// ======================
// Props requirements
// ======================
WeegbrugDropdown.propTypes = {
    variant: PropTypes.string,
    disabled: PropTypes.bool,
    savedData: PropTypes.object,
    handleBlur: PropTypes.func,
    setFieldValue: PropTypes.func,
    touched: PropTypes.object,
    errors: PropTypes.object,
    viewValueOnly: PropTypes.bool.isRequired,
    action: PropTypes.string,
    hideLabel: PropTypes.bool,
    values: PropTypes.object
};

