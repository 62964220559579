import Grid from "@mui/material/Grid2";
import React, {useEffect, useState} from "react";
import customClasses from "./TemplateForm.module.css";
import SearchIcon from "../../Controls/SearchIcon";
import PropTypes from "prop-types";
import {FormTextField} from "./Components/FormTextField";
import {FastField} from "formik";
import SimpleDialog from "../../Dialog/SimpleDialog";
import {Typography} from "@mui/material";
import SearchLocationAdressDialog from "../Templates/DialogDetails/SearchLocationAdressDialog";
import {FormAutocompleteField} from "./Components/FormAutocompleteField";
import {setLocationConditions} from "./Components/TemplateUtils";

export default function TemplateFormInvoice(props) {

    const [openDialog, setOpenDialog] = useState(false);
    const group = "invoiceaddress";

    const {
        formField: {
            invoiceaddress_description,
            invoiceaddress_street_name,
            invoiceaddress_house_no,
            invoiceaddress_house_no_addition,
            invoiceaddress_postal_code,
            invoiceaddress_city_name,
        }
    } = props;

    // =====================
    // Handle selected company
    // =====================
    const handleSelected = (selectedCompany) => {
        props.setFieldValue(group + '.description',  (selectedCompany?.description || selectedCompany?.name || '').substring(0, 50));
        props.setFieldValue(group + '.street_name', selectedCompany.street_name || '');
        props.setFieldValue(group + '.house_no', selectedCompany.house_no || selectedCompany.building_number || '');
        props.setFieldValue(group + '.house_no_addition', selectedCompany.house_no_addition || selectedCompany.building_number_addition || '');
        props.setFieldValue(group + '.postal_code', selectedCompany.postal_code || selectedCompany.postal_zone || '');
        props.setFieldValue(group + '.city_name', selectedCompany.city_name || '');
        selectedCompany?.id ? props.setFieldValue(group + '.id', selectedCompany?.id) : null;
        selectedCompany?.id && selectedCompany?.entity ? props.setFieldValue(group + '.entity', selectedCompany?.entity) : null;

        setLocationConditions(group, selectedCompany?.conditions,  props.setFieldValue)
        closeModal();
    };

    // =====================
    // Handle Modal
    // =====================
    const openModal = () => {
        setOpenDialog(true);
    }
    const closeModal = () => {
        setOpenDialog(false);
    };

    useEffect(() => {
        if (!props.project || Object.keys(props.project).length === 0 || props.action !== 'add') {
            return;
        }
        if (!props.templateFormInitialised) {
            handleSelected(props.project.region.defaultInvoiceAddress || [])
        }
    }, [props.project])

    // =====================
    // Dialog
    // =====================
    const dialog = (
        <SimpleDialog
            title="Zoek locatie"
            fields={props}
            size="lg"
            fullWidth={true}
            open={openDialog}
            handleClose={closeModal}>
            <SearchLocationAdressDialog
                name={'name'}
                dataSources={['vihb', 'companies', 'weighBridge', 'relations']}
                handleLocationSelected={handleSelected}
            />
        </SimpleDialog>
    )

    return <React.Fragment>
        {dialog}
        {/*===========================================================================*/}
        {/*Block Divider*/}
        {/*===========================================================================*/}
        <Grid className={customClasses.borderDivider} container size={12}/>
        <div className={customClasses.blockIdentifier}><strong>2</strong></div>
        {/*===========================================================================*/}
        {/*Block Content
        {/*===========================================================================*/}
        <Grid container size={12}>
            <Grid size={{xs: 11, md: 3}}>
                {invoiceaddress_description.label} <br/>
                <Typography sx={{
                    fontSize: 10,
                    fontStyle: 'italic',
                    textTransform: 'uppercase'
                }}>{props.values.form_type === 'B2' ? (invoiceaddress_description.labelExtra) : null}</Typography>
            </Grid>
            <Grid size={1}>
                <SearchIcon
                    color="rgb(113, 43, 1)"
                    handleClick={openModal}
                    disabled={props.disabled}
                />
            </Grid>
            <Grid size={{xs: 12, md: 8}}>
                <FastField
                    autoComplete="new-password"
                    sizeClass={customClasses.inputFull}
                    name={group + "." + invoiceaddress_description.name}
                    disabled={props.disabled}
                    filterField={'name'}
                    dataSources={['vihb', 'companies', 'weighBridge', 'relations']}
                    component={FormAutocompleteField}
                    handleSelected={handleSelected}
                    setFieldValue={props.setFieldValue}
                />
            </Grid>
        </Grid>
        <Grid container size={12}>
            <Grid size={{xs: 11, md: 3}}>
                {invoiceaddress_street_name.label}
            </Grid>
            <Grid size={1}/>
            <Grid size={{xs: 12, md: 8}}>
                <FastField
                    autoComplete="new-password"
                    name={group + "." + invoiceaddress_street_name.name}
                    disabled={props.disabled}
                    sizeClass={customClasses.inputMedium}
                    component={FormTextField}
                />
                <FastField
                    autoComplete="new-password"
                    name={group + "." + invoiceaddress_house_no.name}
                    type='number'
                    disabled={props.disabled}
                    sizeClass={customClasses.inputSmall}
                    component={FormTextField}
                />
                <FastField
                    autoComplete="new-password"
                    name={group + "." + invoiceaddress_house_no_addition.name}
                    disabled={props.disabled}
                    sizeClass={customClasses.inputSmall}
                    component={FormTextField}
                />
            </Grid>
        </Grid>
        <Grid container size={12}>
            <Grid size={{xs: 11, md: 3}}>
                {invoiceaddress_postal_code.label}
            </Grid>
            <Grid size={1}/>
            <Grid size={{xs: 12, md: 8}}>
                <FastField
                    name={group + "." + invoiceaddress_postal_code.name}
                    disabled={props.disabled}
                    sizeClass={customClasses.inputSmall}
                    component={FormTextField}
                    autoComplete="new-password"
                />
                <FastField
                    autoComplete="new-password"
                    name={group + "." + invoiceaddress_city_name.name}
                    disabled={props.disabled}
                    sizeClass={customClasses.inputLarge}
                    component={FormTextField}
                />
            </Grid>
        </Grid>
    </React.Fragment>
}

TemplateFormInvoice.propTypes = {
    disabled: PropTypes.bool.isRequired,
    formField: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func,
    action: PropTypes.string,
    values: PropTypes.object,
    data: PropTypes.object,
    project: PropTypes.object,
    templateFormInitialised: PropTypes.bool
};