import React, {useEffect, useState} from 'react'
import {getIn} from 'formik'
import {Autocomplete, CircularProgress, Paper, TextField} from '@mui/material'
import {styled} from "@mui/material/styles";
import PropTypes from "prop-types";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import i18n from "../../../../i18n/i18n";
import search from "../../../../store/actions/SearchDataService";
import {useAsync} from "react-async-hook";

const CssTextAutocomplete = styled(Autocomplete)({
    '& .MuiInput-underline:before': {
        borderBottom: '1px dashed rgb(109, 51, 26) ',
        borderRight: '1px dashed rgb(109, 51, 26) '
    },
    '&  .Mui-disabled.MuiInput-underline:before': {
        borderBottom: '1px dashed #CECECE',
        borderRight: '1px dashed  #CECECE'
    },
    '& .MuiInput-underline:hover::before': {
        borderBottom: '1px solid rgb(109, 51, 26)',
        borderRight: '1px solid rgb(109, 51, 26)',
        borderWidth: 1
    },
    '&  .Mui-disabled.MuiInput-underline:hover::before': {
        borderBottom: '1px dashed #CECECE',
        borderRight: '1px dashed  #CECECE'
    },
    '& .MuiInput-underline:after': {
        borderBottom: '1px solid rgb(109, 51, 26)',
        borderRight: '1px solid rgb(109, 51, 26)'
    },

    //Error input border
    '& .MuiInput-underline.Mui-error:before': {
        borderRight: '1px dashed #f44336',
    },
    '& .MuiInput-underline.Mui-error:after': {
        borderBottom: '1px dashed #f44336',
    },
});

const debouncedSearch = AwesomeDebouncePromise(search, 500);
/**
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const FormAutocompleteField = props => {
    const {error, helperText, field, form, readOnly, handleSelected, type, disabled, setFieldValue} = props
    const isTouched = getIn(form.touched, field.name)
    const errorMessage = getIn(form.errors, field.name)
    const [filteredData, setFilteredData] = useState([]);
    const [initialized, setInitialized] = useState(false);
    const inputField = props?.field?.filterField || props?.filterField || null;
    const dataSources = props?.field?.dataSources || props?.dataSources || [];
    const typeDefault = 'text';
    const [inputText, setInputText] = useState(field.value || '');

    useEffect(() => {
        setInitialized(true);
    }, []);

    // Use useAsync to handle the debounced search
    const asyncSearch = useAsync(async () => {
        if (inputText.length < 3 || !initialized) return [];

        const result = await debouncedSearch({
            filterValue: inputText,
            filterField: inputField,
            dataSources,
            uniqueField: 'name',
        });
        setFilteredData(result);
    }, [inputText]);

    const handleChange = (text) => {
        setInputText(text);
        if (setFieldValue) {
            setFieldValue(field.name, text);
        }
    };

    /**
     * Custom - Handle change of user dropdown
     * @param e
     * @param option
     * @param inputValue
     * @returns {Element}
     */
    const handleRenderOption = (e, option, inputValue) => {
        const matches = match(option[inputField], inputValue, {insideWords: true, requireMatchAll: true});
        const parts = parse(option[inputField], matches);
        const {key, ...optionProps} = e;
        return ( <li key={key} {...optionProps}>
                <div>
                    {
                        parts.map((part, index) => (
                            <span
                                key={index}
                                style={{
                                    fontWeight: part.highlight ? 700 : 400,
                                }}
                            >
                                {part.text}
                            </span>
                        ))
                    }
                </div>
            </li>
        )
    }

    /**
     *
     * @param options
     * @param inputValue
     * @returns {*}
     */
    const filterOptions = (options, {inputValue}) => {
        return options?.filter((option) => {
                return option[inputField]?.toString().toLowerCase().includes(inputValue.toLowerCase())
            }
        );
    };

    return (
        <CssTextAutocomplete
            {...field}
            size={"small"}
            options={filteredData || []}
            fullWidth={true}
            freeSolo={true}
            getOptionLabel={(option) =>
                typeof option === 'string' ? option : option[inputField]
            }
            value={field?.value || ''}
            disabled={disabled}
            filterOptions={filterOptions}
            PaperComponent={(props) => (
                <Paper {...props} style={{padding: '10px'}}>
                    <div style={{padding: '10px', color: '#888'}}>
                        {i18n.t('LOOKING_FOR')}
                    </div>
                    {props.children}
                </Paper>
            )}
            onChange={(event, value) => {
                if (value) {
                    handleSelected(value);
                }
            }}
            onInputChange={(event, newInputValue, reason) => {
                if (reason === 'selectOption') {
                    return;
                }
                if (setFieldValue) {
                    setFieldValue(field.name, newInputValue)
                }
                handleChange(newInputValue)
            }}
            renderOption={(props, option, {inputValue}) => handleRenderOption(props, option, inputValue)}
            renderInput={(params) => {
                return <TextField
                    {...params}
                    variant="standard"
                    slotProps={{
                        input: {
                            ...params.InputProps,
                            readOnly: readOnly || false,
                            endAdornment: asyncSearch.loading ? <CircularProgress color="inherit" size={20}/> : null
                        }
                    }}
                    type={type || typeDefault}
                    key={params.id}
                    error={error ?? Boolean(isTouched && errorMessage)}
                    helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
                />
            }}
        />
    )
}

FormAutocompleteField.propTypes = {
    form: PropTypes.object,
    field: PropTypes.object,
    error: PropTypes.string,
    sizeClass: PropTypes.string,
    readOnly: PropTypes.bool,
    helperText: PropTypes.string,
    type: PropTypes.string,
    dataSources: PropTypes.array.isRequired,
    filterField: PropTypes.string.isRequired,
}
