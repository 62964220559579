/**
 *
 * @param oldObject
 * @param updatedProperties
 * @returns {*}
 */
export const updateObject = <T extends object>(oldObject: T, updatedProperties: Partial<T>): T => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

/**
 * Function to check UUID V4
 * @param {string} uuid
 * @returns {boolean}
 */
export const isUuidValid = (uuid: string) => {
    const regexUuid = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

    return regexUuid.test(uuid);
}


/**
 * Asynchronously compares two objects deeply for equality.
 * @param obj1 - The first object to compare.
 * @param obj2 - The second object to compare.
 * @returns A Promise that resolves to `true` if the objects are deeply equal, otherwise `false`.
 */
export async function deepEqual(
    obj1: Record<string, any> | null,
    obj2: Record<string, any> | null
): Promise<boolean> {
    if (
        typeof obj1 === 'object' && obj1 !== null &&
        typeof obj2 === 'object' && obj2 !== null
    ) {
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) return false;

        for (const key of keys1) {
            if (key === 'logo_image') continue; // Skip 'logo_image' key
            if (key === 'created_by') continue; // Skip 'created_by' key
            if (!obj2.hasOwnProperty(key) || !(await deepEqual(obj1[key], obj2[key]))) {
                // console.log(`Property '${key}' differs: ${obj1[key]} !== ${obj2[key]}`);
                return false;
            }
        }

        return true;
    }

    return obj1 === obj2;
}
