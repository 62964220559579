import React from "react";
import "./Truck.css"; // Styles for the truck

const Truck = ({ show }) => {
    if (!show) return null;

    return (
        <div className="kiepwagen">
            <div className="truck-body">
                <div className="dump-bed"></div>
            </div>
            <div className="cab"></div>
            <div className="cab-window"></div>
            <div className="wheel left-wheel">
                <div className="rim"></div>
                <div className="tread"></div>
                <div className="outer-tread"></div>
            </div>
            <div className="wheel right-wheel">
                <div className="rim"></div>
                <div className="tread"></div>
                <div className="outer-tread"></div>
            </div>
            <div className="wheel left-wheel-rear">
                <div className="rim"></div>
                <div className="tread"></div>
                <div className="outer-tread"></div>
            </div>
            <div className="wheel right-wheel-rear">
                <div className="rim"></div>
                <div className="tread"></div>
                <div className="outer-tread"></div>
            </div>

            <div className="exhaust-pipe"></div>
            <div className="smoke">
                <div className="smoke-puff"></div>
                <div className="smoke-puff"></div>
                <div className="smoke-puff"></div>
            </div>
        </div>
    );
};

export default Truck;
