import {GridEditModes, useGridApiContext, useGridRootProps} from "@mui/x-data-grid-premium";
import formatLicensePlate from "../../../shared/formatLicensePlate";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import {TextField} from "@mui/material";
import * as React from "react";
import PropTypes from "prop-types";
import {LicensePlateInput} from "../../Inputs/LicensePlateInput";

//Field

const LicensePlate  = React.memo((props) => {
    const {value} = props;

    return <LicensePlateInput value={value}/>
});

LicensePlate.displayName = 'LicensePlate';
LicensePlate.propTypes = {
    value: PropTypes.string,
}

//Edit Field
function EditLicensePlate(props) {
    const {id, value, field} = props;
    const rootProps = useGridRootProps();
    const apiRef = useGridApiContext();

    const handleChangeComment = (value) => {

        const delayDebounceFn = setTimeout(async () => {
            let formatValue;
            formatValue = formatLicensePlate(value)
            const isValid = await apiRef.current.setEditCellValue({id, field, value: formatValue});

            if (isValid && rootProps.editMode === GridEditModes.Cell) {
                apiRef.current.stopCellEditMode({id, field, cellToFocusAfter: 'below'});
            }
        }, 100)
        return () => clearTimeout(delayDebounceFn)
    }

    const handleChangeDebounce = async (e) => {
        searchAPIDebounced(e.target.value);
    };
    const searchAPIDebounced = AwesomeDebouncePromise(handleChangeComment, 50);

    return (
        <TextField
            size={'small'}
            onChange={(e) => handleChangeDebounce(e)}
            defaultValue={value}
            sx={{height: '100%'}}
        />
    );
}

EditLicensePlate.propTypes = {
    field: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
};

// ===========================
// Render LicensePlate
// ===========================
export function renderLicensePlate(params) {
    // if (params.value == null) {
    //     return '';
    // }
    if (typeof params.id !== 'number') {
        return ''
    }
    return <LicensePlate value={params.value}/>;
}

// ===========================
// Render Edit LicensePlate
// ===========================
export function renderEditLicensePlate(params) {
    return <EditLicensePlate {...params} />;
}