import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Autocomplete, TextField} from "@mui/material";
import {fetchData} from "../../../../../store/actions/dataTableAction";
import PropTypes from "prop-types";
import {getTableData} from "../../../../../store/selectors/datatableSelector";
import {styled} from "@mui/material/styles";
import i18n from "../../../../../i18n/i18n";

const StyledTextField = styled(TextField)({
    '& .MuiInput-underline:before': {
        borderBottom: '1px dashed rgb(109, 51, 26) ',
        borderRight: '1px dashed rgb(109, 51, 26) '
    },
    '&  .Mui-disabled.MuiInput-underline:before': {
        borderBottom: '1px dashed #CECECE ',
        borderRight: '1px dashed  #CECECE '
    },
    '& .MuiInput-underline:after': {
        borderBottom: '1px solid rgb(109, 51, 26)',
        borderRight: '1px solid rgb(109, 51, 26)'
    },
});

export default function UnitTypeDropdown(props) {
    const dispatch = useDispatch();
    const unitType = useSelector(state => getTableData(state, "unit_type"));

    // ======================
    // Get Data
    // ======================
    const fetchUnitType = function () {
        dispatch(fetchData('/unittype', 'unit_type', 100, 0, null));
    }

    useEffect(() => {
        if (((unitType) && unitType?.items?.length > 0) || (props.action === 'view' && props.savedData?.state_id !== '10')) {
            return;
        }
        if (props.viewValueOnly) {
            return;
        }
        fetchUnitType();
    }, [])


    // ======================
    // Selectbox
    // ======================
    const GetUnitTypeSelectBox = (items) => {

        return (
            <React.Fragment>
                <Autocomplete
                    key={props.values?.unit_type_id}
                    id="unit_type_id"
                    name="unit_type_id"
                    options={items || []}
                    onOpen={props.handleBlur}
                    style={props.margin ? {marginTop: "16px", marginBottom: '16px'} : null}
                    // value={props.values.unitType ? props.values.unitType.description || null : null}
                    value={items ? items.find((element) => element.id === props.values?.unit_type_id) || null : null}
                    isOptionEqualToValue={(option, value) => {
                        if (!value) return false;
                        let selected;
                        if (!value.description) {
                            selected = value;
                        } else {
                            selected = value.description
                        }
                        return option.description === selected;
                    }}
                    noOptionsText={i18n.t('NO_OPTIONS')}
                    getOptionLabel={(option) => {
                        return (
                            option.description ? option.description : option || ''
                        )
                    }}
                    onChange={(e, value) => {
                        props.setFieldValue("unit_type_id", value !== null ? value.id : "");
                        props.setFieldValue("unitType.description", value !== null ? value.description : "");
                    }}
                    disabled={props.disabled}
                    renderInput={(params) => <TextField
                        {...params}
                        required
                        slotProps={{
                            inputLabel: {shrink: true},
                        }}
                        helperText={Boolean(props.errors?.unit_type_id && props.touched?.unit_type_id) && props.errors.unit_type_id}
                        error={Boolean(props.errors?.unit_type_id && props.touched?.unit_type_id)}
                        name="unit_type_id"
                        id="unit_type_id"
                        label={props.hideLabel ? null : 'Eenheidstype' }
                        variant={props.variant || 'outlined'}/>}
                />
            </React.Fragment>
        );
    }

    const ViewOnlyUnitTypeSelectBox = (items) => {
        let unitTypeDescription = "";
        if (props.savedData?.unitType?.description) {
            unitTypeDescription = props.savedData.unitType?.description;
        } else {
            const unitTypeFound = items ? items.find((element) => element.id === props.savedData?.unit_type_id) : null
            unitTypeDescription = unitTypeFound?.description;
        }
        return <StyledTextField
                label={props.hideLabel ? null : 'Eenheidstype' }
                fullWidth
                disabled={props.disabled}
                value={unitTypeDescription ?? ''}
                variant={props.variant || "outlined"}
                sx={{marginBottom: "16px", marginTop: '16px'}}
            />
    }

    let unitTypeSelectbox = "";
    if ((props.action !== 'view' || props.savedData.state_id === '10') && !props.viewValueOnly) {
        unitTypeSelectbox = GetUnitTypeSelectBox(unitType?.items);
    } else {
        unitTypeSelectbox = ViewOnlyUnitTypeSelectBox(unitType?.items);
    }

    // ======================
    // Return Selectbox
    // ======================
    return (
        <React.Fragment>
            {
                unitTypeSelectbox
            }
        </React.Fragment>
    )
}

// ======================
// Props requirements
// ======================
UnitTypeDropdown.propTypes = {
    variant: PropTypes.string,
    disabled: PropTypes.bool,
    savedData: PropTypes.object,
    handleBlur: PropTypes.func,
    setFieldValue: PropTypes.func,
    touched: PropTypes.object,
    errors: PropTypes.object,
    viewValueOnly: PropTypes.bool.isRequired,
    action: PropTypes.string,
    hideLabel: PropTypes.bool,
    margin: PropTypes.bool,
    values : PropTypes.object
};

